import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import OxCard from "../components/molecules/OxCard";
import OxLoader from '../components/tokens/OxLoader';

const GET_APPROVED_EMAILS = gql`
    query {
     approvedEmails {
        email
        role
     }
    }
`;

const ApprovedEmails = () => {
    // List of approved emails with their role
    const { loading, data } = useQuery(GET_APPROVED_EMAILS, {});
    // Track the search term
    const [searchTerm, setSearchTerm] = useState("");
    // filters the list of emails based on the search term (case-insensitive)
    const filteredItems = data?.approvedEmails.filter(item => item.email.toLowerCase().includes(searchTerm.toLowerCase()));

    // Search bar to display at the top of the OxCard
    const searchBar = () => (
        <input
            placeholder="Search for a user"
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
        />
    );

    return (
        <OxCard header={{
            title: "Approved Emails",
            buttons: [searchBar]
        }}>
            {loading && (<OxLoader />)}
            {!loading && (
                <table>
                    <tr>
                        <th>Email</th>
                        <th>Role</th>
                    </tr>
                    {filteredItems.map(item => (
                        <tr>
                            <td>{item.email}</td>
                            <td>{item.role}</td>
                        </tr>
                    ))}
                </table>
            )}
        </OxCard>
    );
};

export default ApprovedEmails; 
