import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import OxCard from '../components/molecules/OxCard';
import OxInput from '../components/tokens/forms/OxInput';
import OxTextarea from '../components/tokens/forms/OxTextarea';
import OxSelect from '../components/tokens/forms/OxSelect';
import OxSoftAlert from '../components/molecules/OxSoftAlert';
import OxButton from '../components/molecules/OxButton';

import '../styles/pages/_interior.scss';
import { GET_USER_INFO } from '../api/user/query';
import * as segment from '../helpers/segmentLogger';

const PROJECT_NUMBER_REGEX = '^(0|^(5)([0-9]{6})$)$';

const priorities = `
<div>
  <p>
    0 - High Risk: Fire, Injury, Line down > 24 hours, Customer product contamination, 'Claim or Damages' language from customer
  </p>
  <p>1 - Emergency: Line down < 24 hours, Excessive mis-sorts, Other</p>
  <p>
    2 - Non-Emergency: General efficiency reduction, Modifications support,
    Internal investigation, Other
  </p>
  <p>
    3 - Information: Documentation, Need DPP number for quote, Part
    identification, Drawing/BOM changes, Other
  </p>
  <p>
    4 – A proactive or planned service at the customer’s request not tied to a technical issue
  </p>
  </div>
  `;
const recordTypes = `
<div>
  <p>
    Tech Support - Customer has an issue that needs to be solved utilizing our tech support teams
  </p>
  <p>
    Planned Services - Customer requests a proactive, planned service
    (health check, installation support, spare parts audit, etc.)
  </p>
  <p>
    Information - Customer needs information (documentation, drawings, etc.)
    about their equipment purchase
  </p>
  </div>
  `;

const projectNumberInfo = `
  <div>
    <p>
      This field can be either:
    </p>
    <ul>
      <li>Empty</li>
      <li>A single digit - 0</li>
      <li>A seven digit number starting with 5</li>
    </ul>
  </div>
`;

const projectNumberTitle = `This field can be either empty, 0 or a seven digit number starting with 5.`;

const prioritySelectOptions = [
  {
    optionText: '0 - High Risk',
    optionValue: '0 - High Risk'
  },
  {
    optionText: '1 - Emergency',
    optionValue: '1 - Emergency'
  },
  {
    optionText: '2 - Non-Emergency',
    optionValue: '2 - Non-Emergency'
  },
  {
    optionText: '3 - Information Request',
    optionValue: '3 - Information Request'
  },
  {
    optionText: '4 - Planned Service',
    optionValue: '4 - Planned Service'
  }
];

const recordTypeOptions = [
  {
    optionText: 'Information',
    optionValue: '0121U000000eDiT'
  },
  {
    optionText: 'Planned Service',
    optionValue: '0121U000000eDiU'
  },
  {
    optionText: 'Tech Support',
    optionValue: '0121U000000eDiV'
  }
];

const groupSelectOptions = [
  {
    optionText: 'DPE',
    optionValue: 'DPE'
  },
  {
    optionText: 'Integration',
    optionValue: 'Integration'
  }
];

const paidBySelectOptions = [
  {
    optionText: 'Give Away',
    optionValue: 'Give Away'
  },
  {
    optionText: 'Warranty',
    optionValue: 'Warranty'
  },
  {
    optionText: '50/50',
    optionValue: '50/50'
  },
  {
    optionText: 'Customer',
    optionValue: 'Customer'
  }
];

// Stateless case creator methods
export const CaseCreatorMethods = () => {
  const _getNameFromEmail = data => {
    if (data && data.userInfo && data.userInfo.email) {
      const { email } = data.userInfo;
      return `${email.split('.')[0]} ${email.split('.')[1].split('@')[0]}`;
    }
    return '';
  };

  const _projectNumberHasError = projectNumber => {
    return projectNumber
      ? !RegExp(PROJECT_NUMBER_REGEX).test(projectNumber)
      : false;
  };

  const _isFormInvalid = (
    projectNumber,
    group,
    priority,
    customerAccountNumber,
    customerContactName,
    customerAccountName,
    caseSubject,
    caseDescription,
    recordType
  ) => {
    return !(
      group &&
      priority &&
      customerAccountNumber &&
      customerContactName &&
      customerAccountName &&
      caseSubject &&
      caseDescription &&
      recordType &&
      !_projectNumberHasError(projectNumber)
    );
  };

  return {
    _getNameFromEmail,
    _projectNumberHasError,
    _isFormInvalid
  };
};

const CaseCreator = () => {
  const { loading, data } = useQuery(GET_USER_INFO);
  const {
    _getNameFromEmail,
    _projectNumberHasError,
    _isFormInvalid
  } = CaseCreatorMethods();
  const [group, _setGroup] = useState(null);
  const [priority, _setPriority] = useState(null);
  const [customerAccountNumber, _setCustomerAccountNumber] = useState(null);
  const [customerContactName, _setCustomerContactName] = useState(null);
  const [customerAccountName, _setCustomerAccountName] = useState(null);
  const [caseSubject, _setCaseSubject] = useState(null);
  const [caseDescription, _setCaseDescription] = useState(null);
  const [recordType, _setRecordType] = useState(null);
  const [projectNumber, _setProjectNumber] = useState(null);

  /**
   * Logs an event to Google Analytics before the form is submitted
   *
   * @returns {Boolean}
   */
  const _onSubmit = () => {
    segment.logEvent({
      actionName: 'Successfully Added a Case in Case Creator',
      categoryName: 'Case Creator'
    });

    return true;
  };

  /**
   * Returns option value for a given option
   * @param {*} optionText
   */
  const _getRecordTypeValueByOptionText = optionText => {
    return recordTypeOptions.find(opt => opt.optionText === optionText)
      .optionValue;
  };

  /**
   * Set the record type based on the selected priority
   * @param {*} event
   */
  const _handlePriorityChange = event => {
    // Set priority in application state
    _setPriority(event.target.value);

    // Get the record type value for the selected priority
    const recordTypeOptionValue = _getRecordTypeValueByOptionText(
      {
        '0 - High Risk': 'Tech Support',
        '1 - Emergency': 'Tech Support',
        '2 - Non-Emergency': 'Tech Support',
        '3 - Information Request': 'Information',
        '4 - Planned Service': 'Planned Service'
      }[event.target.value]
    );

    // Set record type in application state
    _setRecordType(recordTypeOptionValue);

    // Set form's record type option
    document.querySelector(
      'form select[name="recordType"]'
    ).value = recordTypeOptionValue;
  };

  return (
    <OxCard header={{ title: 'Case Creator' }} id="case-creator">
      <form
        onSubmit={_onSubmit}
        action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
        method="POST"
      >
        <input type="hidden" name="orgid" value="00D1U000000y3Dd" />
        <input
          type="hidden"
          name="retURL"
          value={`${window.location.origin}/case-creator/thanks`}
        />
        <fieldset className="ox-fieldset">
          <div className="units-row">
            <div className="unit-100 large-screen-unit-40">
              <div className="units-row">
                <div className="forever-unit-50 margin-bottom">
                  <OxSelect
                    name="00N1U00000U71lW"
                    placeholder="Please Select"
                    floatLabel={false}
                    label="Group"
                    required
                    handleKeyUp={event => _setGroup(event.target.value)}
                    giDataAttr="case-creator__group"
                    noAnalytics
                    options={groupSelectOptions}
                  />
                </div>
                <div className="forever-unit-50">
                  <OxSelect
                    name="priority"
                    tooltipContent={priorities}
                    tooltipScrollable
                    placeholder="Please Select"
                    floatLabel={false}
                    label="Priority"
                    required
                    handleKeyUp={event => _handlePriorityChange(event)}
                    giDataAttr="case-creator__priority"
                    noAnalytics
                    options={prioritySelectOptions}
                  />
                </div>
              </div>
            </div>
            <div className="unit-100 large-screen-unit-60">
              <div className="units-row">
                <div className="hide forever-unit-50 margin-bottom">
                  <OxSelect
                    name="recordType"
                    tooltipContent={recordTypes}
                    tooltipScrollable
                    placeholder="Please Select"
                    floatLabel={false}
                    label="Record Type"
                    handleKeyUp={event => _setRecordType(event.target.value)}
                    required
                    giDataAttr="case-creator__record-type"
                    noAnalytics
                    options={recordTypeOptions}
                  />
                </div>
                <div className="forever-unit-100">
                  <OxInput
                    inputType="text"
                    name="00N1U00000U73cB"
                    placeholder="Enter Equipment Serial Number"
                    floatLabel={false}
                    label="Equipment Serial Number"
                    required={false}
                    giDataAttr="case-creator__serial-number"
                    noAnalytics
                  />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset className="ox-fieldset">
          <div className="units-row">
            <div className="unit-50 margin-bottom">
              <OxInput
                inputType="text"
                title={projectNumberTitle}
                pattern={PROJECT_NUMBER_REGEX}
                name="00N1U00000U71li"
                placeholder="What Is The Project Number Associated With This Case?"
                floatLabel={false}
                label="Project Number"
                handleKeyUp={event => _setProjectNumber(event.target.value)}
                giDataAttr="case-creator__project-number"
                tooltipContent={projectNumberInfo}
                maxLength={7}
                hasError={_projectNumberHasError(projectNumber)}
              />
            </div>
            <div className="unit-50 margin-bottom">
              <OxSelect
                name="00N1U00000U71ld"
                placeholder="Please Select"
                floatLabel={false}
                label="Paid By"
                giDataAttr="case-creator__paid-by"
                options={paidBySelectOptions}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="ox-fieldset">
          <div className="units-row">
            <div className="unit-33">
              <OxInput
                inputType="text"
                name="00N1U00000U71lE"
                placeholder="Please Select"
                floatLabel={false}
                label="Customer Account Name"
                required
                handleKeyUp={event =>
                  _setCustomerAccountName(event.target.value)
                }
                giDataAttr="case-creator__customer-account-name"
                noAnalytics
              />
            </div>
            <div className="unit-33">
              <OxInput
                inputType="text"
                name="00N1U00000U71lF"
                placeholder="Please Select"
                floatLabel={false}
                label="Customer Account Number"
                required
                handleKeyUp={event =>
                  _setCustomerAccountNumber(event.target.value)
                }
                giDataAttr="case-creator__customer-account-number"
                noAnalytics
              />
            </div>
            <div className="unit-33">
              <OxInput
                inputType="text"
                name="00N1U00000U71m3"
                placeholder="Please Select"
                floatLabel={false}
                label="Customer Contact Name"
                required
                handleKeyUp={event =>
                  _setCustomerContactName(event.target.value)
                }
                giDataAttr="case-creator__customer-contact-name"
                noAnalytics
              />
            </div>
          </div>
          <div className="units-row">
            <div className="unit-33 margin-bottom">
              <OxInput
                inputType="text"
                name="email"
                placeholder="Please Select"
                floatLabel={false}
                label="Customer Account Email"
                giDataAttr="case-creator__customer-account-email"
                noAnalytics
              />
            </div>
            <div className="unit-33 margin-bottom">
              <OxInput
                inputType="text"
                name="phone"
                placeholder="Please Select"
                floatLabel={false}
                label="Customer Account Phone"
                giDataAttr="case-creator__customer-account-phone"
                noAnalytics
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="ox-fieldset">
          <div className="units-row">
            <div className="unit-40 margin-bottom">
              <OxInput
                inputType="text"
                name="subject"
                placeholder="Please Enter a Subject For This Case"
                floatLabel={false}
                label="Case Subject"
                required
                handleKeyUp={event => _setCaseSubject(event.target.value)}
                giDataAttr="case-creator__subject"
              />
            </div>
            <div className="unit-60 margin-bottom">
              <OxTextarea
                name="description"
                placeholder="Enter A Description Of The Issue"
                floatLabel={false}
                label="Case Description"
                required
                handleKeyUp={event => _setCaseDescription(event.target.value)}
                giDataAttr="case-creator__description"
                className="width-100"
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="ox-fieldset">
          <div className="units-row">
            <div className="unit-50 margin-bottom">
              <OxButton
                id="case-creator__submit-button"
                text="Add Your Case"
                buttonType="submit"
                element="button"
                isDisabled={loading}
                type="blue"
                helperClass={`width-100 ${
                  _isFormInvalid(
                    projectNumber,
                    group,
                    priority,
                    customerAccountNumber,
                    customerContactName,
                    customerAccountName,
                    caseSubject,
                    caseDescription,
                    recordType
                  )
                    ? 'btn-disabled'
                    : ''
                }`}
                giDataAttr={
                  _isFormInvalid(
                    projectNumber,
                    group,
                    priority,
                    customerAccountNumber,
                    customerContactName,
                    customerAccountName,
                    caseSubject,
                    caseDescription,
                    recordType
                  )
                    ? 'case-creator__submit-button-disabled'
                    : 'case-creator__submit-button-enabled'
                }
              />
            </div>
            <div className="unit-50">
              <OxSoftAlert
                isOutlined
                alertType="warning"
                id="case-creator-alert"
                giDataAttr="case-creator__alert"
              >
                <input
                  id="00N1U00000U71ly"
                  type="hidden"
                  maxLength="100"
                  name="00N1U00000U71ly"
                  size="20"
                  value={_getNameFromEmail(data)}
                />
                <input
                  id="00N1U00000U71lx"
                  type="hidden"
                  maxLength="80"
                  name="00N1U00000U71lx"
                  size="20"
                  value={data && data.userInfo && data.userInfo.email}
                />
                <p>This case will be entered with your name and email.</p>
              </OxSoftAlert>
            </div>
          </div>
        </fieldset>
      </form>
    </OxCard>
  );
};

export default CaseCreator;
